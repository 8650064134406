import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/navbar/Navbar';
import Sidebar from './components/sidebar/Sidebar';
import {BrowserRouter, Routes, Route}  from 'react-router-dom'
import { Dashboard } from './pages/dashboard';
import { Container } from 'react-bootstrap';
import { Storemanagement } from './pages/storemanagement';
import CreateProduct from './pages/createProduct';
import Selectproduct from './pages/selectproduct';
import Login from './pages/login';
import { useSelector,useDispatch } from 'react-redux';
import { getAllBrand, getAllCategory, siteSetting } from './util/apis';
import { useEffect,useState } from 'react';
import Profile from './pages/profile';
import Order from './pages/order';
import { isAuthenticated } from './util/helper';
import { logout } from './redux/authRedux';
import Account from './pages/account';
import Changepassword from './pages/changepassword';
import Promotion from './pages/promotion';
import OpeningTime from './pages/openingTime';
import Medias from './pages/media';

function App() {
  const [isSidebarOpen, setisSidebarOpen] = useState(false)
  const menuOpen = (data) =>{
      setisSidebarOpen(data);
  }
  const vendorUser = useSelector((state) => state.vendorInfo.currentUser)
  const dispatch = useDispatch()
  const sessionexpire = () =>{
    isAuthenticated(vendorUser?.accessToken)? console.log("Connected") : dispatch(logout());
  }
  useEffect(() => {
    getAllCategory(dispatch)
    getAllBrand(dispatch)
    sessionexpire()
    siteSetting(dispatch)
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
      {vendorUser?
        <>
          <Sidebar menuOpen={menuOpen} isSidebarOpen={isSidebarOpen}/>
          <div className='content-area'>
            <Navbar menuOpen={menuOpen}/>
            <Container fluid>
              <Routes>
                <Route path='/' element={<Dashboard />} />
                <Route path='/store' element={<Storemanagement />} />
                <Route path='/create-product' element={<CreateProduct />} />
                <Route path='/select-product' element={<Selectproduct />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/orders' element={<Order />} />
                <Route path='/accounts' element={<Account />} />
                <Route path='/changepassword' element={<Changepassword />} />
                <Route path='/promote' element={<Promotion />} />
                <Route path='/media' element={<Medias />} />
                <Route path='/openingtime' element={<OpeningTime />} />
              </Routes>
            </Container>
          </div>
        </>
        :
        <Login/>
      }
      </BrowserRouter>
    </div>
  );
}

export default App;
