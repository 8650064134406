import { createSlice } from "@reduxjs/toolkit";


const authSlice = createSlice({
  name: "vendor",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      const data = action.payload;
      state.currentUser = {...state.currentUser,...data};
    },
    sessionUpdate: (state, action) => {
      state.isFetching = false;
      let updateSessionData = action.payload
      state.currentUser = {...state.currentUser, ...updateSessionData};
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logout: (state) => {
      state.currentUser = null;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout, sessionUpdate } = authSlice.actions;
export default authSlice.reducer;