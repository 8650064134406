import { createSlice } from "@reduxjs/toolkit";

export const notificationSlide = createSlice({
  name: "notification",
  initialState: {
    notification: {
      sms:"",
      status:null,
      reload:false
    },
    isFetching: false,
    error: false,
  },
  reducers: {
    //UPDATE
    updateNotificationStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateNotificationSuccess: (state, action) => {
      state.isFetching = false;
      const data = action.payload;
      state.notification = {...state.notification,...data};
    },
    updateNotificationFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {
  updateNotificationStart,
  updateNotificationSuccess,
  updateNotificationFailed
} = notificationSlide.actions;

export default notificationSlide.reducer;
