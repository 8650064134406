import React, { useState } from 'react'
import { changepassword } from '../util/apis';
import {useDispatch, useSelector} from "react-redux"
import { logout } from '../redux/authRedux';
export default function Changepassword() {
  const dispatch = useDispatch()
  const vendorUser = useSelector((state) => state.vendorInfo.currentUser)
  const [changePassword, setchangePassword] = useState({})
  const [error, seterror] = useState(null)
  const handlePassChange = (e)=>{
    setchangePassword(prev=>{
        return{...prev, [e.target.name]:e.target.value};
    })
  }

  const handleSubmit = (e) =>{
    e.preventDefault()
    if (changePassword.newpassword === changePassword.confirmnewpassword) {
        changepassword(vendorUser?.vendormail, changePassword).then((res)=>{
            if (res.status === 200) {
                seterror(null)
                dispatch(logout())
            } else {
                seterror(res.response.data)
            }
        })
    } else {
        seterror("Confirm password didn't matched")
    }

  }

  return (
    <div className='changepassword'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-4 col-md-4 offset-lg-4 offset-md-4'>
                    <div className='custom-card d-block'>
                        <h3 className='mb-4'>Change Password</h3>
                        <form className='theme-form' onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label>Current Password</label>
                                <input className='form-control' type='password' onChange={handlePassChange} name='password' required/>
                            </div>
                            <div className='form-group'>
                                <label>New Password</label>
                                <input className='form-control' type='password' onChange={handlePassChange} name='newpassword' required/>
                            </div>
                            <div className='form-group'>
                                <label>Confirm new Password</label>
                                <input className='form-control' type='password' onChange={handlePassChange} name='confirmnewpassword' required/>
                            </div>
                            <p>{error? error : ""}</p>
                            <button type='submit' className='btn btn-success d-block w-100'>Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
