import React, { useEffect } from 'react'
import { useState } from 'react';
import './media.css'
import {loadMedia,addMedia, uploadMedia, deleteSpecificMedia, deleteShopMediaFromStorage } from '../../util/apis';
import { useSelector } from 'react-redux';
import ImageList from './ImageList';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Media({uploadImg}) {
  let files = [];
  let library = {};
  const [upImg, setupImg] = useState(false);
  const [mediaLibrary, setmediaLibrary] = useState(true);
  const [sliders, setsliders] = useState(false);
  const [brandFolders, setbrandFolders] = useState(false);
  const [uploader, setuploader] = useState(false);
  const vendorUser = useSelector((state) => state.vendorInfo.currentUser)
  const brandAlbums = useSelector((state) => state.brand.brands)
  const [medias, setmedias] = useState([]);
  useEffect(() => {
    loadMedia(vendorUser?.accessToken, vendorUser?._id).then((res=>{
      setmedias(res.data)
    }))
  }, [])
  let sliderLibrary = [...medias];
  sliderLibrary = sliderLibrary.filter(img=>img.fileTo === 'banner');

  const loadtotalMedia = () =>{
    loadMedia(vendorUser?.accessToken, vendorUser?._id).then((res)=>{
      setmedias(res.data)
    })
  }

  

  const handleUpimg = () => {
    setupImg(true);
    setmediaLibrary(false);
  };

  const upImage = (fileName) => {
    // const imgUrl = `${process.env.REACT_APP_BASE_URL}/api/alluploads/${fileName}`;
    uploadImg(fileName);
  };

  const handleUpload = (e) => {
    setuploader(true)
    files = e.target.files;
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== 'image/jpeg' && files[i].type !== 'image/png' && files[i].type !== 'image/jpg' && files[i].type !== 'image/pdf') {
        console.log("File is not in expected format");
      }else{
        console.log("File is in expected format");
        library = { entityId: vendorUser?._id, fileName: files[i].name, fileType: files[i].type, fileFor:"vendor" };
        addMedia(vendorUser?.accessToken, library)
        .then((res)=>{
          console.log("Media added to database successfully");
        })
        .catch((e) => {
          console.log("error", e);
        });
        console.log("File append");
        data.append("file", files[i]);
      }
    }

    uploadMedia(vendorUser?.accessToken, data, vendorUser?._id)
    .then(() => {
        console.log("Media uploaded to server successfully");
    })
    .catch((e) => {
        console.log("error", e);
    })
    .then(() => {
        loadMedia(vendorUser?.accessToken, vendorUser?._id).then((res=>{
            setmedias(res.data)
        }))
        setuploader(false)
        setmediaLibrary(true)
        setupImg(false)
    });
  }

  const handleDelete = (imagename) => {
      deleteSpecificMedia(vendorUser?.accessToken, vendorUser?._id, imagename?._id)
      .then((res) => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      });

      deleteShopMediaFromStorage(vendorUser?.accessToken, vendorUser?._id, imagename.fileName)
      .then((res) => {
        console.log("success");
        loadMedia(vendorUser?.accessToken, vendorUser?._id).then((res=>{
            setmedias(res.data)
        }))
      })
      .catch((e) => {
        console.log("error", e);
      })
  };


  return (
    <div className='h-100'>
      <div className='list h-100'>
        <div className='table custom-card category-table h-100 d-block'>
          <div className='mediabody'>
              <div className='mediawrap' style={mediaLibrary? {display:"flex"} : {display:"none"}}>
                <div className='image-list'>
                  <div class="single-upload-img"><form><h1>Drop Files Here To Upload</h1><p>Or</p><input type="file" onChange={handleUpload} multiple/><a>Select Files</a></form></div>
                  {medias?.map((image)=>(
                    <ImageList image={image} upImage={upImage} handleDelete={handleDelete} key={image?._id} />
                  ))}
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}
