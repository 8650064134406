import React, { useState } from 'react'
import { Container, Row, Col,FormGroup,Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { fetchAccounnts } from '../../util/apis'
import { OrderList } from './OrderList'

export default function Accounts() { 
  const vendorUser = useSelector((state) => state.vendorInfo.currentUser)
  const notification = useSelector((state)=> state.notification.notification)
  const [orders, setorders] = useState([])
  const [fromDate, setfromDate] = useState()
  const [toDate, settoDate] = useState()
  const handleSubmit = (e) =>{
    e.preventDefault();
    fetchAccounnts(vendorUser?.accessToken, fromDate, toDate, vendorUser?._id).then((res)=>{
        setorders(res?.data)
    })
  }
  return (
    <div className='account-inner'>
        <Container fluid>
            <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
                {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
                <p>{notification.sms}</p>
            </div>
            <div className='custom-form'>
                <form className='custom-card d-block' onSubmit={handleSubmit}>
                    <Row>
                        <Col lg={5} md={5}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>From date</label>
                                <input type='date' className='form-control' value={fromDate} onChange={(e) => setfromDate(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col lg={5} md={5}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>To date</label>
                                <input type='date' className='form-control' value={toDate} onChange={(e) => settoDate(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col lg={2} md={2} >
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Search</label>
                                <Button variant='success' className='w-100' type='submit' disabled={fromDate && toDate? false : true}>Submit</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
            <OrderList orders={orders} />
        </Container>
    </div>
  )
}
