import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { deleteProduct, deleteProdutFromStore, updateStoreProduct } from '../../util/apis'

export default function YourProduct({product, getYourStoreProduct}) {
  const allCategory = useSelector((state)=> state.category.categories)
  const vendorInfo = useSelector((state)=> state.vendorInfo.currentUser)
  const dispatch = useDispatch()
  const [searchQuery, setsearchQuery] = useState(product.categoryId?.nameInEnglish+" ")
  const [isEdit, setisEdit] = useState(true)
  const [inputs, setinputs] = useState({
    shopid:product.shopid,
    productid:product.productid._id,
    categoryId:product.categoryId?._id,
    color:product.color === undefined? "" : product.color,
    size:product.size === undefined? "" : product.size,
    basicPrice:product.basicPrice,
    salePrice:product.salePrice,
    discount:product.discount === undefined? 0 : product.discount,
    status:product.status
  })
  const [discountPrice, setdiscountPrice] = useState(inputs.salePrice)
  const [discountPercent, setdiscountPercent] = useState(inputs.discount)
  const handlePercentage = (e) =>{
    setdiscountPercent(e.target.value)
    setdiscountPrice(inputs.basicPrice - ((e.target.value/100)*inputs.basicPrice))
  }

  const handlePercentageFromSalePrice = (e) =>{
    setdiscountPrice(e.target.value)
    setdiscountPercent(((inputs.basicPrice-e.target.value)/inputs.basicPrice)*100)
  }

  const handleChange = (e) =>{
    setinputs(prev=>{
        return{...prev, [e.target.name]:e.target.value};
    });
  }

  const handleUpdate = (e) =>{
    e.preventDefault();
    let finalProduct = {...inputs, salePrice:discountPrice, discount: discountPercent }
    updateStoreProduct(vendorInfo?.accessToken, finalProduct, vendorInfo?._id, product?._id).then((res)=>{
        if (res.status === 200) {
            setisEdit(true)
            getYourStoreProduct()
        } else {
            
        }
    })
  }

  const handleDelete = (product) =>{
    if (product?.productid?.type === 'food') {
        deleteProdutFromStore(vendorInfo?.accessToken, vendorInfo?._id, product?._id).then((res)=>{
            if (res.status === 200) {
                deleteProduct(vendorInfo?.accessToken, vendorInfo?._id, product?.productid?._id).then((res)=>{
                    if (res.status === 200) {
                        getYourStoreProduct()
                    }else{
                        console.log(res);
                    }
                })
            } else {
                console.log(res);
            }
        })
    } else {
        deleteProdutFromStore(vendorInfo?.accessToken, vendorInfo?._id, product?._id).then((res)=>{
            if (res.status === 200) {
                getYourStoreProduct()
            } else {
               console.log(res); 
            }
        })
    }
  }
  return (
    <li style={{marginRight:"10px"}}>
        <form onSubmit={handleUpdate}>
            <div className='single-product'>
                <div className='product-img'>
                    {product?.productid?.addedBy?.added === 'vendor'? <img className='w-100' src={`${process.env.REACT_APP_BASE_URL}/alluploads/shops/${product?.productid?.addedBy?.id}/${product.productid.photo}`} /> : <img className='w-100' src={`${process.env.REACT_APP_BASE_URL}/alluploads/${product.productid.photo}`} />}
                </div>
                <h5>
                    {product.productid.nameInEnglish}
                    <p>{product.categoryId?.nameInEnglish} | {product.size} | {product.salePrice}BDT | {product?.cashback? `${product?.cashback} ৳ Cashback` : ""} </p>
                    <div className={product.status === true? "productStatus active" : "productStatus inactive"}>{product.status === true? "Active" : "Inactive"}</div>
                </h5>
                <FontAwesomeIcon className='delete' icon={faTrashAlt} onClick={()=>handleDelete(product)} />
                {isEdit? <FontAwesomeIcon icon={faEdit} onClick={()=>setisEdit(false)} /> : <FontAwesomeIcon icon={faTimes} onClick={()=>setisEdit(true)} />}
            </div>
            <>
            {isEdit? ""
            :
            <>
                <div className='form-group d-flex flex-wrap justify-content-between'>
                    <input type='number' className='form-control' placeholder='Qty' value={inputs.quantity} name='quantity' onChange={(e)=>handleChange(e)} disabled={isEdit} />
                    <input type='text' className='form-control' placeholder='Size' value={inputs.size} name='size' onChange={(e)=>handleChange(e)} disabled={isEdit} />
                    <input type='text' className='form-control' placeholder='Color' value={inputs.color} name='color' onChange={(e)=>handleChange(e)} disabled={isEdit} />
                    <input type='text' className='form-control' placeholder='Basic Price' value={inputs.basicPrice} name='basicPrice' onChange={(e)=>handleChange(e)} disabled={isEdit} required />
                    <input type='text' className='form-control' placeholder='Discount (%)' name='discount' value={discountPercent} onChange={(e)=>handlePercentage(e)} disabled={isEdit} />
                    <input type='text' className='form-control' placeholder='Sale Price' name='salePrice' value={discountPrice} onChange={(e)=>handlePercentageFromSalePrice(e)} disabled={isEdit} required />
                </div>
                <select className='form-control' name='status' onChange={(e)=>handleChange(e)} disabled={isEdit} required>
                    {inputs.status === true? <option value='true'>Active</option> : <option value='false'>Inactive</option>}
                    <option value="">Status</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                </select>
                <div className='select-category'>
                    <input type='text' placeholder='Search Category' className='form-control w-100' value={searchQuery} onChange={(e)=>setsearchQuery(e.target.value)} disabled={isEdit} required />
                    <ul>
                        {allCategory.filter(category => category.status === true).filter((category)=>{
                            if (searchQuery === "") {
                                return ""
                            }else if(category.nameInEnglish.toLowerCase().includes(searchQuery.toLowerCase())){
                                return category
                            }
                        }).map((category)=>(
                            <li>
                                <label htmlFor={category._id}>{category.nameInEnglish}</label>
                                <input value={category._id} name='categoryId' className='d-none' onClick={(e)=>{handleChange(e); setsearchQuery(category.nameInEnglish+" ")}} id={category._id} />
                            </li>
                        ))}
                    </ul>
                </div>
                {isEdit? "" : <button className='btn primary w-100' style={{color:"#ffffff"}} type='submit'>Update</button>}
            </>
            }
            </>
        </form>
    </li>
  )
}
