import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { getAllCategory, getAllProduct, getYourProducts } from '../../util/apis'
import { useDispatch,useSelector } from 'react-redux'
import SingleProduct from './SingleProduct'
import YourProduct from './YourProduct'

export default function SelectProduct() {
    const vendorInfo = useSelector((state)=> state.vendorInfo.currentUser)
    const [allProducts, setallProducts] = useState([])
    const [yourProducts, setyourProducts] = useState([])
    const dispatch = useDispatch()
    const [findProduct, setfindProduct] = useState(false)
    const [yourProduct, setyourProduct] = useState(true)
    const [searchQuery, setsearchQuery] = useState("")
    const [searchQuery2, setsearchQuery2] = useState("")

    const getYourStoreProduct = () =>{
        getYourProducts(vendorInfo?._id).then((res)=>{
            setyourProducts(res.data)
        })
    }

    useEffect(() => {
        getAllProduct(dispatch).then((res)=>{
            setallProducts(res.data)
        })
        getYourStoreProduct()
    }, [])
    
  return (
    <section className='select-products'>
        <ul className='tabs'>
            <li className={yourProduct? "active" : ""} onClick={()=>{setyourProduct(true); setfindProduct(false)}}>Your Products</li>
            <li className={findProduct? "active" : ""} onClick={()=>{setyourProduct(false); setfindProduct(true)}}>Find Products</li>
        </ul>
        <div className='tab-wrap'>
            <div className='single-wrap' style={yourProduct? {display:"block"} : {display:"none"}}>
                <form >
                    <input placeholder='Search for product...' onChange={(e)=>setsearchQuery2(e.target.value)} value={searchQuery2} className='form-control mt-3' />
                </form>
                <ul className='justify-content-start align-items-start'>
                    {yourProducts?.filter(product=>product.status === true).filter(product=>{
                        if (searchQuery2 === "") {
                            return product
                        }else if(product.productid?.nameInEnglish.toLowerCase().includes(searchQuery2.toLowerCase())){
                            return product
                        }else{
                            return null
                        }
                    }).map(product=>(
                        <YourProduct product={product} key={product._id} getYourStoreProduct={getYourStoreProduct}  />
                    ))}
                </ul>
            </div>
            <div className='single-wrap' style={findProduct? {display:"block"} : {display:"none"}}>
                <form >
                    <input placeholder='Search for product...' onChange={(e)=>setsearchQuery(e.target.value)} value={searchQuery} className='form-control mt-3' />
                </form>
                <ul>
                    {allProducts.filter(product=>product.status === true).filter(product=>{
                        if (searchQuery === "") {
                            return product
                        }else if(product.nameInEnglish.toLowerCase().includes(searchQuery.toLowerCase())){
                            return product
                        }else{
                            return null
                        }
                    }).map((product)=>(
                        <SingleProduct product={product} key={product._id} getYourStoreProduct={getYourStoreProduct}  />
                    ))}
                </ul>
            </div>
        </div>
    </section>
  )
}
