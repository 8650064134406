import React from 'react'
import OrderList from '../components/order/OrderList'

export default function Order() {
  return (
    <div className='orderpage'>
        <OrderList/>
    </div>
  )
}
