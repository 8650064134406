import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function ImageList({image, upImage, handleDelete}) {
  return (
    <div className='single-img' key={image?._id}>
        <img src={`${process.env.REACT_APP_BASE_URL}/alluploads/shops/${image?.entityId}/${image.fileName}`} alt={image.fileName} />
        <div className='upload-btn'>
            <button className='up-btn' type='button' onClick={()=>upImage(image.fileName)}>Insert Image</button>
        </div>
        <button onClick={() => handleDelete(image)}><FontAwesomeIcon icon={faTimes} /></button>
    </div>
  )
}
