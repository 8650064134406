import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import logo from '../../img/logo.png'
import { useDispatch } from 'react-redux'
import { logout } from '../../redux/authRedux'

export default function Sidebar({menuOpen,isSidebarOpen}) {
  const dispatch = useDispatch()
  return (
    <div className={isSidebarOpen? 'sidebar-open' : 'sidebar-outer'} onClick={()=>menuOpen(false)} >
      <ul className='sidebar'>
          <Link to="/" className='logo'><img src={logo} alt='logo' /></Link>
          <NavLink to="/" activeStyle>Dashboard</NavLink>
          <NavLink to="/store" activeStyle>Product Maintenance</NavLink>
          <NavLink to="/select-product" activeStyle>Your Product</NavLink>
          <NavLink to="/accounts" activeStyle>Accounts</NavLink>
          <NavLink to="/orders" activeStyle>Orders</NavLink>
          <NavLink to="/invoices" activeStyle>Invoices</NavLink>
          <NavLink to="/report" activeStyle>Report</NavLink>
          <NavLink to="/openingtime" activeStyle>Opening Times</NavLink>
          <NavLink to="/marketing" activeStyle>Marketing</NavLink>
          <NavLink to="/promote" activeStyle>Promote your Business</NavLink>
          <NavLink to="/notifications" activeStyle>Notification</NavLink>
          <NavLink to="/media" activeStyle>Media</NavLink>
          <NavLink to="/support" activeStyle>Uddokta Support</NavLink>
          <NavLink to="/user" activeStyle>User </NavLink>
          <NavLink to="/changepassword" activeStyle>Change Password </NavLink> 
          <a onClick={()=>dispatch(logout())}>Logout </a>
      </ul>
    </div>
  )
}
