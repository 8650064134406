import React, { useState,useEffect } from 'react'
import { getOrderData } from '../../util/apis'
import { useSelector } from 'react-redux'
import SingleOrderRow from './SingleOrderRow'
import axios from 'axios'

export default function OrderList() {
  const [orders, setorders] = useState([])
  const vendorUser = useSelector((state) => state.vendorInfo.currentUser)

  useEffect(() => {
    getOrderData(vendorUser?.accessToken, vendorUser?._id).then((res)=>{
        setorders(res.data);
    })
  }, [])
  
  const handleUpdateOrder = async (orderid, status) =>{
    try {
        const res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/order/${vendorUser?._id}/${orderid}`, data:{status:status} ,headers:{token: 'Bearer '+ vendorUser?.accessToken}})
        getOrderData(vendorUser?.accessToken, vendorUser?._id).then((res)=>{
            setorders(res.data);
        })
    } catch (error) {
        console.log(error);
    }
  }
  

  return (
    <div className='list'>
        <div className='list-header'>
            <div>Status</div>
            <div>Order ID</div>
            <div>Payment Status</div>
            <div>Subtotal</div>
        </div>
        <ul>
            {orders?.map((order)=>(
                <SingleOrderRow order={order} key={order?._id} handleUpdateOrder={handleUpdateOrder} />
            ))}
        </ul>
    </div>
  )
}
