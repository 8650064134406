import React,{useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircleArrowDown, faTimes, faListDots, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import adbanner from '../../img/uddoktabanner.jpg'
import {useSelector} from 'react-redux'
import { adBid, getbidbyadidanduddoktaid } from '../../util/apis'
import axios from 'axios'

export default function SingleCampaingItem({item,  lastTimeOfBid,  adsLimitTime}) {
  const [bidlist, setbidlist] = useState([])
  const [show, setshow] = useState(false)
  const [maxValue, setmaxValue] = useState([])
  const vendorInfo = useSelector((state)=> state.vendorInfo.currentUser)
  const [bidinfo, setbidinfo] = useState({
    uddokta_id:vendorInfo?._id,
    amount:null,
    ad_id:item?._id
  })
  const handleSubmit = (e) =>{
    e.preventDefault();
    adBid(vendorInfo?._id, vendorInfo?.accessToken, bidinfo).then((res)=>{
        if (res.status === 201) {
            window.location.href= '/promote'
        }else{
            console.log(res.response.data);
        }
    })
  }

  const fetchbidlist = () =>{
    getbidbyadidanduddoktaid(vendorInfo?._id, vendorInfo?.accessToken, item?._id).then((res)=>{
        setbidlist(res.data)
    })
  }

  const fetchMaxBidValue = async () =>{
    let res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/biding/${vendorInfo?._id}/${item?._id}?maximumbid=true`, headers:{token: 'Bearer '+ vendorInfo?.accessToken}})
    setmaxValue(res.data)
  }

  
  useEffect(() => {
    fetchbidlist()
    fetchMaxBidValue()
  }, [])


  return (
    <>
        <div className='single-ad-row'>
            <div className='singlead-img'>
                <img src={adbanner} width="100%" />
            </div>
            <div className='singlead-info w-50'>
                <h3>{item.title}</h3>
                <p><span style={{width:'70px'}}>Ad Place</span>: {item.place}</p>
                <p><span style={{width:'70px'}}>Position</span>: {item.position}</p>
            </div>
            <div className='singlead-info w-50'>
                <h3>৳ {item.minBidLimit} <sub>(Min)</sub></h3>
                <p><span style={{width:'120px'}}>Last Date of Bid</span>: {lastTimeOfBid?.toLocaleDateString()}</p>
                <p><span style={{width:'120px'}}>Ad Expired In</span>: {adsLimitTime?.toLocaleDateString()}</p>
            </div>
            <div className='singlead-info w-50'>
                <h3>Top Bid Til Now: ৳ {maxValue.length? maxValue[0].amount : 0}</h3>
                <p><span style={{width:'120px'}}>Total Bid:</span>: {bidlist?.length}</p>
                <form className='d-flex' onSubmit={handleSubmit}>
                    <input type='number' placeholder='Enter your amount' className='form-control' min="10" onChange={(e)=>setbidinfo({...bidinfo, amount:e.target.value})} />
                    <button type="submit" className="btn" disabled={bidinfo.amount === null? true : false}>Submit</button>
                </form>
            </div>
            <FontAwesomeIcon icon={faCircleArrowDown} style={{fontSize:"25px", padding:'10px',color:'#faaa1c',cursor:'pointer'}} onClick={()=>setshow(!show)} />
        </div>
        {show? (
            <div className='single-ad-row-bottom'>
                <h4>Applied</h4>
                <ul>
                    {bidlist?.map((e)=>{
                      let appliedDate = new Date(e.createdAt)
                      return(
                        <li>
                          <h5>{appliedDate?.toLocaleDateString()}</h5>
                          <h5>৳ {e.amount}</h5>
                          <p style={{padding:'2px 8px',color:'#ffffff',fontSize:'14px',background:"#faaa1c",marginRight:'10px',borderRadius:"2px"}}>Pending...</p>
                      </li>
                      )
                    })}
                </ul>
            </div>
        ) : ""}
    </>
  )
}
