import React from 'react'
import Accounts from '../components/accounts/Accounts'

export default function Account() {
  return (
    <div className='account-page'>
      <Accounts/>
    </div>
  )
}
