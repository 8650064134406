import React, { useState } from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faBars, faBell, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import avater from '../../img/profile.png'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/authRedux'
import { updateStatus } from '../../util/apis'


export default function Navbar({menuOpen}) {
  const vendorUser = useSelector((state) => state.vendorInfo.currentUser)
  const location = useLocation()
  const dispatch = useDispatch()
  const handleUpdateStatus = (e) =>{
    updateStatus(dispatch, vendorUser?._id, e.target.checked, vendorUser?.accessToken).then((res)=>{
        console.log(res);
    })
  }
  return (
    <header>
        <Container fluid>
            <Row>
                <Col xl={5} lg={5} md={6} sm={6} xs={2}>
                    <div className='title d-flex align-items-center'>
                        <FontAwesomeIcon icon={faBars} onClick={()=>menuOpen(true)} />
                        <h1>{location.pathname === "/create-product"? "Create Product": location.pathname === "/orders"? "Orders": location.pathname === "/store"? "Product Maintenance": location.pathname === "/accounts"? "Accounts" : location.pathname === "/promote"? "Promote your business" : location.pathname === "/media"? "Manage Media" : "Dashboard"}</h1>
                    </div>
                </Col>
                <Col xl={7} lg={7} md={6} sm={6} xs={10}>
                    <div className='navbar-right'>
                        <div className='header-widget'>
                            <div class="button-cover" style={{marginTop:"20px"}}>
                                <div class="button r" id="button-1">
                                <input type="checkbox" class="checkbox" onChange={handleUpdateStatus} checked={vendorUser?.isStoreOpen}/>
                                <div class="knobs"></div>
                                <div class="layer"></div>
                                </div>
                            </div>
                        </div>
                        <div className='header-widget'>
                            <span></span>
                            <FontAwesomeIcon icon={faBell} />
                        </div>
                        <div className='header-widget'>
                            <span></span>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                        <div className='profile-thumb'>
                            <Link to='/profile' className='d-flex align-items-center'>
                                <img src="../imgs/store.jpg" />
                                <p>{vendorUser.vendorname}
                                    <span className='d-block'>{vendorUser?.businesstype === 'food'? "Restaurent" : vendorUser?.businesstype === 'grocery'? "Grocery" : "Lifestyle"} ({vendorUser?.vendorId})</span>
                                    {vendorUser?.isVerified === true? <img src={`https://api.mrmanik.com/api/alluploads/verified.png`} style={{borderRadius:0}} width={50} /> : ""}
                                </p>
                                <FontAwesomeIcon icon={faAngleDown} />
                            </Link>
                            <ul>
                                <li>
                                    <Link to='/profile' >Profile</Link>
                                </li>
                                <li>
                                    <Link to='/help' >Help</Link>
                                </li>
                                <li>
                                    <a onClick={()=>dispatch(logout())}>Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </header>
  )
}
