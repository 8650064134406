import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'

export default function OrderItem({orderitem}) {
  return (
    <li>
        <div className='d-flex align-items-center'>
            {/* <FontAwesomeIcon icon={faTrashCan} color='#df2b2b' fontSize='18px' style={{marginRight:'8px',cursor:'pointer'}} />
            <input type='number' className='quantity' value={orderitem?.quantity} /> */}
            <p>
            <b>{orderitem?.quantity}x </b> 
            {orderitem?.storeProductid?.productid?.nameInEnglish}
            </p>
        </div>
        <p><b>Tk. {orderitem?.basicAmount}</b></p>
    </li>
  )
}
