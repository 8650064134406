import React, { useState } from 'react'
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faKey, faLock, faTimesCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { forgotPassword, login, sendOtp } from '../util/apis';
import { updateNotificationSuccess } from '../redux/notificationRedux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { faCheck,faTimes } from '@fortawesome/free-solid-svg-icons';


export default function Signin() {
    const [passwordFieldType, setpasswordFieldType] = useState('password')
    const [modal, setmodal] = useState(false)
    const [vendormail, setvendormail] = useState()
    const [password, setpassword] = useState()
    const [phone, setphone] = useState()
    const [error, seterror] = useState("")
    const [recoveryerror, setrecoveryerror] = useState("")
    const notification = useSelector((state)=> state.notification.notification)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

  const handleClick = (e) =>{
    e.preventDefault()
    login(dispatch,{vendormail,password})
    .then(res => {
        if (res.data === undefined) {
            if (res.response.data === 'Vendor not active') {
                seterror("Your account is not active yet");
            } else if(res.response.data === 'Wrong Password') {
                seterror("Wrong Password");
            }else{
                seterror("Something went wrong");
            }
        }else{
            dispatch(updateNotificationSuccess({sms:"Login Success",status:true}))
            navigate('/')
        }
    });
  }

  const generatePassword = () =>{
    const randomPassword =
    Math.random().toString(26).slice(-6) + Math.random().toString(26).slice(-6);
    return randomPassword;
  }

  const handleResetPassword = (e) =>{
    e.preventDefault();
    let vendorInfo = {
        phone: phone,
        password:generatePassword()
    }
    forgotPassword(vendorInfo).then((res)=>{
        if (res.status === 401) {
            setrecoveryerror("You do not have any uddokta account associated with this phone. Please try again with your new phone number.")
        } else {
          sendOtp(phone, {"message":`Dear Uddokta, you have requested for password recovery for your uddokta account. Here is your login credintials. %0aLogin Url: uddokta.mrmanik.com %0aEmail: ${res?.data?.vendormail} %0aPassword: ${vendorInfo?.password} %0aThank you for staying with us` }).then((res)=>{
            if (res?.data?.status_code !== 200 || res === undefined) {
                setrecoveryerror("Sorry something went wrong.Try again.")
            } else {
                dispatch(updateNotificationSuccess({sms:`An recovery password has been sent to your uddokta phone number`,status:true}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                    setmodal(false)
                }, 3000);
            }
          })
        }
      })
  }

  return (
    <>
    {modal?(
        <div className='custom-moder-outer'>
            <div className='custom-modal'>
                <div className='custom-modal-header'>
                    <h2>Reset your password</h2>
                    <FontAwesomeIcon className='custom-modal-close' onClick={()=>setmodal(false)} icon={faTimesCircle} />
                </div>
                <div className='custom-modal-body'>
                    <form className='theme-form' onSubmit={handleResetPassword}>
                        <input className='form-control' onChange={(e)=>setphone(e.target.value)} placeholder='Enter your uddokta phone number' required/>
                        <button className='btn theme-btn' type='submit'>Send</button>
                        <span>{recoveryerror}</span>
                    </form>
                </div>
                <div className='custom-modal-footer'></div>
            </div>
        </div>
    ) : ""}
    <div className='login-page w-100'>
        <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
            {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
            <p>{notification.sms}</p>
        </div>
        <div className='container-fluid'>
            <div className='row h-100'>
                <div className='col-lg-8 col-md-8'>
                    <Slider {...settings} className='w-100'>
                        <div className='item'>
                            <img src='../imgs/login.jpg' className='w-100' alt='login slider' />
                        </div>
                    </Slider>
                </div>
                <div className='col-lg-4 col-md-4 d-flex align-items-center'>
                    <div className='login-prompt'>
                        <div className="text-center">
                            <div>
                                <a href="index.html" className="">
                                    <img src="../imgs/logo.png" alt="" height="20" className="auth-logo logo-light mx-auto"/>
                                </a>
                            </div>

                            <h4 className="font-size-18 mt-4">Welcome Back to your uddokta portal!</h4>
                        </div>

                        <div className="p-2 mt-5">
                            <form onSubmit={handleClick}>

                                <div className="mb-3 auth-form-group-custom mb-4">
                                    <FontAwesomeIcon icon={faUserAlt} className='auti-custom-input-icon' />
                                    <label htmlFor="email">Uddokta E-mail</label>
                                    <input type="text" className="form-control" onChange={e=>setvendormail(e.target.value)} id="email" placeholder="Enter uddokta email"/>
                                </div>

                                <div className="mb-3 auth-form-group-custom mb-4">
                                    <FontAwesomeIcon icon={faKey} className='auti-custom-input-icon' />
                                    <label htmlFor="userpassword">Password</label>
                                    <input type={passwordFieldType} className="form-control" onChange={e=>setpassword(e.target.value)} id="userpassword" placeholder="Enter password"/>
                                    {passwordFieldType === 'password'? <FontAwesomeIcon icon={faEye} className='icon-label' onClick={()=>setpasswordFieldType('text')} /> : <FontAwesomeIcon icon={faEyeSlash} className='icon-label' onClick={()=>setpasswordFieldType('password')} />}
                                </div>

                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="customControlInline"/>
                                    <label className="form-check-label" for="customControlInline">Remember me</label>
                                </div>
                                <p style={{color:"red"}}>{error}</p>
                                <div className="mt-4 text-center">
                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                                </div>

                                <div className="mt-4 text-center">
                                    <a onClick={()=>setmodal(true)} className="text-muted"> <FontAwesomeIcon icon={faLock} /> Forgot your password?</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
