import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { addProductToStore } from '../../util/apis'
import { updateNotificationSuccess } from '../../redux/notificationRedux'

export default function SingleProduct({product, getYourStoreProduct}) {
  const allCategory = useSelector((state)=> state.category.categories)
  const vendorInfo = useSelector((state)=> state.vendorInfo.currentUser)
  const notification = useSelector((state)=> state.notification.notification)
  const dispatch = useDispatch()
  const [searchQuery, setsearchQuery] = useState("")
  const [isEdit, setisEdit] = useState(true)
  const [inputs, setinputs] = useState({})
  const [discountType, setdiscountType] = useState("")

  const [discountPrice, setdiscountPrice] = useState()
  const [discountPercent, setdiscountPercent] = useState(0)
  const handlePercentage = (e) =>{
    setdiscountPercent(e.target.value)
    setdiscountPrice(inputs.basicPrice - ((e.target.value/100)*inputs.basicPrice))
  }

  const handlePercentageFromSalePrice = (e) =>{
    setdiscountPrice(e.target.value)
    setdiscountPercent(((inputs.basicPrice-e.target.value)/inputs.basicPrice)*100)
  }

  const handleChange = (e) =>{
    setinputs(prev=>{
        return{...prev, [e.target.name]:e.target.value};
    });
  }

  const handleAddProductToStore = (e) =>{
    e.preventDefault();
    let finalProduct = {...inputs,shopid: vendorInfo?._id, productid:product?._id, salePrice:discountPrice, discount: discountPercent, status:true }
    addProductToStore(vendorInfo?.accessToken, vendorInfo?._id, finalProduct).then((res)=>{
        if (res.status === 201) {
            dispatch(updateNotificationSuccess({sms:"Product Added",status:true}))
            setTimeout(() => {
                dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);
            setisEdit(true)    
            getYourStoreProduct()
        }else{
            dispatch(updateNotificationSuccess({sms:"Product Added Failed",status:true}))
            setTimeout(() => {
                dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);    
        }
    })
  }

  const handleReset = () =>{
    window.location.href= '/select-product'
  }

  return (
    <>
    <li>
        <form onSubmit={handleAddProductToStore}>
            <div className='single-product'>
                <div className='product-img'>
                    <img className='w-100' src={`${process.env.REACT_APP_BASE_URL}/alluploads/${product.photo}`} />
                </div>
                <h5>{product.nameInEnglish}</h5>
                {isEdit? <FontAwesomeIcon icon={faEdit} onClick={()=>setisEdit(false)} /> : <FontAwesomeIcon icon={faTimes} onClick={()=>handleReset()} />}
            </div>
            <div className='form-group d-flex flex-wrap justify-content-between'>
                <input type='number' className='form-control' placeholder='Qty' value={inputs.quantity} name='quantity' onChange={(e)=>handleChange(e)} disabled={isEdit} />
                <input type='text' className='form-control' placeholder='Size' name='size' onChange={(e)=>handleChange(e)} disabled={isEdit} />
                <input type='text' className='form-control' placeholder='Color' name='color' onChange={(e)=>handleChange(e)} disabled={isEdit} />
                <input type='text' className='form-control' placeholder='Basic Price' name='basicPrice' onChange={(e)=>handleChange(e)} disabled={isEdit} required />
                {vendorInfo?.isVerified === true ?
                (
                    <>
                    <select className='form-control' name='discountType' onChange={(e)=>setdiscountType(e.target.value)} disabled={isEdit}>
                        <option value="">Discount type</option>
                        <option value="discount">Discount</option>
                        <option value="cashback">Cashback</option>
                    </select>
                    {
                        discountType === 'discount'? 
                        <input type='text' className='form-control' placeholder='Enter (%)' name='discount' value={discountPercent} onChange={(e)=>handlePercentage(e)} disabled={isEdit} /> 
                        : 
                        discountType === 'cashback'? 
                        <input type='number' className='form-control' placeholder='Enter amount' name='cashback' onChange={(e)=>handleChange(e)} disabled={isEdit} /> 
                        : 
                        ""
                    }
                    </>
                )
                :
                    <input type='text' className='form-control' placeholder='Enter (%)' name='discount' value={discountPercent} onChange={(e)=>handlePercentage(e)} disabled={isEdit} />
                }
                <input type='text' className='form-control' placeholder='Sale Price' name='salePrice' value={discountPrice} onChange={(e)=>handlePercentageFromSalePrice(e)} disabled={isEdit} required />
            </div>
            <div className='select-category'>
                <input type='text' placeholder='Search Category' className='form-control w-100' value={searchQuery} onChange={(e)=>setsearchQuery(e.target.value)} disabled={isEdit} required />
                <ul>
                    {allCategory.filter(category => category.status === true && category.parentCategory === undefined).filter((category)=>{
                        if (searchQuery === "") {
                            return ""
                        }else if(category.nameInEnglish.toLowerCase().includes(searchQuery.toLowerCase())){
                            return category
                        }
                    }).map((category)=>(
                        <li>
                            <label>{category.nameInEnglish}</label>
                            <input value={category._id} type='checkbox' name='categoryId' onChange={(e)=>{handleChange(e); setsearchQuery(category.nameInEnglish+" ")}} id={category._id} />
                            <div className='subcategory-menu'>
                                {allCategory.filter(c => c.status === true && c.parentCategory === category._id).map((c)=>(
                                    <div>
                                        <input value={c._id} type='checkbox' name='categoryId' onChange={(e)=>{handleChange(e); setsearchQuery(c.nameInEnglish+" ")}} id={c._id} />
                                        {c.nameInEnglish}
                                    </div>
                                ))}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {isEdit? "" : <button className='btn primary w-100' style={{color:"#ffffff"}} type='submit'>Add To Store</button>}
        </form>
    </li>
    </>
  )
}
