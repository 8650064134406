import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ownproductimg from '../../img/custom-product.png'
import addproduct from '../../img/addtostore.png'
import { Link } from 'react-router-dom'

export default function Store() {
  return (
    <section className='store-page'>
        <Row>
            <Col xl={6} lg={6} md={6} sm={6}>
                <div className='custom-card'>
                    <div className='custom-card-img'>
                        <img src={ownproductimg} alt="vector" />
                    </div>
                    <div className='custom-card-content'>
                        <h4>Create own product</h4>
                        <p>Mr Manik gived you the facilities to create your own product.</p>
                        <Link to="/create-product" className='theme-btn-alt'>Create Product</Link>
                    </div>
                </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6}>
                <div className='custom-card'>
                    <div className='custom-card-img'>
                        <img src={addproduct} alt="vector" />
                    </div>
                    <div className='custom-card-content'>
                        <h4>Add Product To Store</h4>
                        <p>You can add own product or select existing product to your store.</p>
                        <Link to="/select-product" className='theme-btn-alt'>Add To Store</Link>
                    </div>
                </div>
            </Col>
        </Row>
    </section>
  )
}
