import { faCheck, faAdd, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState,useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import { Button, Col, Container, FormControl, FormGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BeatLoader from 'react-spinners/BeatLoader'
import './list.css'

export function OrderList({orders}) {
    const [itemPerPage, setitemPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0);
    const pageVisited = pageNumber * itemPerPage;
    const pageCount = Math.ceil(orders?.length / itemPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const [loader, setloader] = useState(false)
    const [searchedQuery, setsearchedQuery] = useState("")


    const displayItems = orders.filter(item=> {
        if (searchedQuery === "") {
          return item
        }
        else if(item.orderId.includes(searchedQuery)) {
          return item
        }
      }).map((item, index) => {
            
            let date = new Date(item.createdAt)
            let prodcost = item?.orderedproducts?.map(p => p.basePrice * p.quantity)
            .reduce((a, b) => a + b, 0);
            let comission = (item?.commission/100)*prodcost
            let com5incomm = ((5/100)*comission)?.toFixed(2)
            let subtotal = item?.orderedproducts?.map(p => p.basicAmount * p.quantity).reduce((a, b) => a + b, 0);
            let balance = prodcost - comission - com5incomm - item?.voucherAmount - (prodcost-subtotal);
            
          return (
            <tr key={index}>
                <td>{item.orderId}</td>
                <td style={{textTransform:'uppercase'}}>{item.orderStatus?.[0].status}</td>
                <td style={{textTransform:'uppercase'}}>{item.transStatus}</td>
                <td style={{textTransform:'uppercase'}}>{item.paymentMode}</td>
                <td>{prodcost}</td>
                <td>{item?.commission}%</td>
                <td>{comission?.toFixed(0)}</td>
                <td>{com5incomm}</td>
                <td></td>
                <td>{prodcost-subtotal}</td>
                <td></td>
                <td>{item?.voucherAmount}</td>
                <td></td>
                <td></td>
                <td>{balance}</td>
                <td>{prodcost}</td>
            </tr>
          );
        });

    return (
        <>
            {orders.length === 0? <p className='text-center mt-2'>No Order Found</p> : 
                <div className='list' style={{paddingLeft:0,paddingRight:0}}>
                    <div className='table custom-Card category-table'>
                        <h5 className='d-flex justify-content-between'>Order List</h5>
                        <div className='table-top'>
                            <div className='form-group'>
                                Show
                                <select onChange={(e)=>setitemPerPage(e.target.value )}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                </select>
                                entries
                            </div>
                            <div className='form-group'>
                                <label>Search:</label>
                                <input type='text' onChange={(e)=>setsearchedQuery(e.target.value)} />
                            </div>
                        </div>
                        <table className='table' style={{marginLeft:"-20px",marginRight:"-20px"}}>
                            <thead>
                                <tr>
                                    <th style={{width:'90px'}}>Order Id</th>
                                    <th>Ord. Status</th>
                                    <th>Pay Status</th>
                                    <th>Pay. By</th>
                                    <th>Prod. Price</th>
                                    <th>Comm. %</th>
                                    <th>Commision</th>
                                    <th>Comm Vat 5%</th>
                                    <th>Online Pay.Char.</th>
                                    <th>Ud. Disc.</th>
                                    <th>Comp. Disc.</th>
                                    <th>Voucher Amount</th>
                                    <th>Ud. Voucher.</th>
                                    <th>Comp. Voucher.</th>
                                    <th>Balance</th>
                                    <th>Ud. Rev.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loader? <BeatLoader color="#36d7b7" style={{textAlign:"center",display:"block"}} /> : displayItems}
                            </tbody>
                        </table>
                    </div>
                    <div className='list-bottom d-flex justify-content-between align-items-center'>
                    </div>
                </div>
            }
        </>
    )
}
