import React from 'react'
import { Container, Row, Col, FormGroup, FormControl, Button, InputGroup } from 'react-bootstrap'
import { useState } from 'react';
import Compressor from 'compressorjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct,addProductToStore, addMedia } from '../../util/apis';
import { updateNotificationSuccess } from '../../redux/notificationRedux';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import Media from '../Media/Media';

export default function Createproduct() {
    const [slug, setslug] = useState();
    const [imgUrl, setimgUrl] = useState(null)
    const [inputs, setinputs] = useState({})
    const [storeInput, setstoreInput] = useState({})
    const [error, seterror] = useState("")
    const [productInfo, setproductInfo] = useState(true)
    const [storeInfo, setstoreInfo] = useState(false)
    const [finalizing, setfinalizing] = useState(false)
    const [photo, setphoto] = useState(null)
    const brands = useSelector((state)=>state.brand.brands)
    const vendorUser = useSelector((state) => state.vendorInfo.currentUser)
    const notification = useSelector((state)=> state.notification.notification)
    const allCategory = useSelector((state)=> state.category.categories)
    const [searchQuery, setsearchQuery] = useState("")
    const [savedProduct, setsavedProduct] = useState({})
    const [discountType, setdiscountType] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modal, setModal] = useState(false)
    const uploadImg = (childData) =>{
        setModal(!modal)
        setimgUrl(childData)
    }

    const slugify = (string) => {
        const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;';
        const b = 'aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz------';
        const p = new RegExp(a.split('').join('|'), 'g');
        return string?.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) 
        .replace(/&/g, '-and-') // Replace & with ‘and’
        .replace(/[^(\u0980-\u09FF)\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
      }

  const handleChange = (e)=>{
    setinputs(prev=>{
        return{...prev, [e.target.name]:e.target.value};
    });
    setslug(slugify(inputs.nameInEnglish))
  }
  const handleAddToStoreChange = (e)=>{
    setstoreInput(prev=>{
        return{...prev, [e.target.name]:e.target.value};
    });
  }

  const handleAddProduct = (e) =>{
    e.preventDefault();
    let product = {...inputs, photo:imgUrl, productSlug:slug, status:false, addedBy:{id:vendorUser?._id, added:"vendor"}}
        addProduct(dispatch, vendorUser?.accessToken, product).then((res)=>{
            if (res !== undefined) {
                setsavedProduct(res?.data)
                setproductInfo(false);
                setstoreInfo(true)
            } else {
                dispatch(updateNotificationSuccess({sms:"Product Added Failed",status:false}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                console.log("error");
            }
    })
  }

  const [discountPrice, setdiscountPrice] = useState()
  const [discountPercent, setdiscountPercent] = useState()
  const handlePercentage = (e) =>{
    setdiscountPercent(e.target.value)
    setdiscountPrice(storeInput.basicPrice - ((e.target.value/100)*storeInput.basicPrice))
  }

  const handlePercentageFromSalePrice = (e) =>{
    setdiscountPrice(e.target.value)
    setdiscountPercent(((storeInput.basicPrice-e.target.value)/storeInput.basicPrice)*100)
  }

  const handleStoreAdd = (e) =>{
    e.preventDefault();
    let finalProduct = {...storeInput,shopid: vendorUser?._id, productid:savedProduct?._id, salePrice:discountPrice, discount: discountPercent,status:false }
    addProductToStore(vendorUser?.accessToken, vendorUser?._id, finalProduct).then((res)=>{
        if (res.status === 201) {
            dispatch(updateNotificationSuccess({sms:"Product Added. Wait for admin approval.",status:true}))
            setTimeout(() => {
                dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);
            setproductInfo(false);
            setstoreInfo(false)
            setfinalizing(true);
        }else{
            dispatch(updateNotificationSuccess({sms:"Product Added Failed",status:true}))
            setTimeout(() => {
                dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);    
        }
    })
  }

//   const uploadImg = (e) =>{
//     const file = e.target.files[0];
//     if (!file) {
//         return;
//     }
//     new Compressor(file, {
//         quality: 0.8,
//         width: 250,
//         height:250,
    
//         // The compression process is asynchronous,
//         // which means you have to access the `result` in the `success` hook function.
//         success(result) {
//           const formData = new FormData();
    
//           // The third parameter is required for server
//           formData.append('file', result, result.name);
//           setphoto(result.name)
    
//           // Send the compressed image file to server with XMLHttpRequest.
//           try {
//             let res = axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/media/upload/${vendorUser?._id}`, data:formData ,headers:{token: 'Bearer '+ vendorUser?.accessToken}}).then((res)=>{
//                 if (res?.data === 'success') {
//                     let library = { entityId: vendorUser?._id, fileName: result.name, fileType: result.type, fileFor:"vendor" }
//                     addMedia(vendorUser?.accessToken, library)
//                     .then((res)=>{
//                         console.log("success");
//                     })
//                     .catch((e) => {
//                         console.log("error", e);
//                     });
//                 } else {
//                     console.log(res);
//                 }
//               })
//           } catch (error) {
            
//           }
//         setimgUrl(URL.createObjectURL(result))
//         },
//         error(err) {
//           console.log(err.message);
//         },
//       });
//   }
  console.log(imgUrl);
  return (
    <>
    {modal? (
        <div className='modal-outer'>
            <FontAwesomeIcon icon={faTimes} onClick={()=>setModal(!modal)} />
            <Media uploadImg={uploadImg} />
        </div>
    ) : ""}
    <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
        {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
        <p>{notification.sms}</p>
    </div>
    <div className='create-product'>
        <ul className='form-steps'>
            <li className={productInfo? 'single-step active' : 'single-step'}>
                <span>1.</span> Product Information
            </li>
            <li className={storeInfo? 'single-step active' : 'single-step'}>
                <span>2.</span> Store information
            </li>
            <li className={finalizing? 'single-step active' : 'single-step'}>
                <span>3.</span> Finalization
            </li>
        </ul>
        <form className='step wrap create-form' onSubmit={handleAddProduct} style={productInfo? {display:"block"} : {}}>
            <Row>
                <Col lg={9} md={8}>
                    <Row>
                        <Col lg={6} md={6}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Product Name (Write in Bangla)</label>
                                <FormControl type='text' name='nameInBangla' onChange={handleChange} required/>
                            </FormGroup>
                        </Col>
                        <Col lg={6} md={6}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Product Name (Write in English)</label>
                                <FormControl type='text' name='nameInEnglish' onChange={handleChange} required/>
                            </FormGroup>
                        </Col>
                        <Col lg={6} md={6}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Select Category Type</label>
                                <select className='form-control' name='type' onChange={handleChange} required>
                                    <option>Select Type</option>
                                    <option value="food">Restaurant</option>
                                    <option value="grocery">Supermarket</option>
                                    <option value="fashion">Lifestyle</option>
                                </select>
                            </FormGroup>
                        </Col>
                        <Col lg={6} md={6}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Product Slug</label>
                                <FormControl type='text' value={slug} />
                            </FormGroup>
                        </Col>
                        <Col lg={6} md={6}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Select Product Brand</label>
                                <select className='form-control' name='brand' onChange={handleChange}>
                                    <option>Select Type</option>
                                    {brands.map((brand)=>(
                                        <option value={brand._id}>{brand.brandName}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col lg={6} md={6}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Product Code</label>
                                <FormControl type='text' name='productCode' onChange={handleChange} required/>
                            </FormGroup>
                        </Col>
                        <Col lg={12} md={12}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Description</label>
                                <textarea className='form-control' rows={5} name='description' onChange={handleChange}></textarea>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col lg={3} md={4}>
                    {imgUrl !== null? <img src={`${process.env.REACT_APP_BASE_URL}/alluploads/shops/${vendorUser?._id}/${imgUrl}`} onClick={uploadImg} className='w-100 mb-3' /> : <div className="form-group upload-img" onClick={uploadImg} style={{cursor:"pointer"}}>
                        <label>
                        <svg width="72" height="57" viewBox="0 0 72 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M68.0057 10.3334V7.00004H60.8858V10.3334H56.3326L52.7727 0.333374H19.1595L15.5995 10.3334H0.366455V57H71.5657V10.3334H68.0057ZM64.4458 50.3334H7.48638V17H20.733L24.293 7.00004H47.6392L51.1992 17H64.4458V50.3334Z" fill="#E1E1E1"/>
                        <path d="M35.9661 17C32.7977 17 29.7004 17.8797 27.066 19.528C24.4315 21.1762 22.3782 23.5189 21.1657 26.2597C19.9532 29.0006 19.636 32.0166 20.2541 34.9264C20.8722 37.8361 22.398 40.5088 24.6384 42.6066C26.8788 44.7044 29.7333 46.133 32.8408 46.7118C35.9484 47.2906 39.1694 46.9935 42.0966 45.8582C45.0239 44.7229 47.5258 42.8003 49.2861 40.3336C51.0464 37.8668 51.986 34.9667 51.986 32C51.9812 28.0231 50.2919 24.2104 47.2887 21.3983C44.2854 18.5862 40.2134 17.0044 35.9661 17ZM35.9661 40.3333C34.2059 40.3333 32.4852 39.8446 31.0216 38.9289C29.558 38.0132 28.4173 36.7117 27.7437 35.189C27.0701 33.6663 26.8938 31.9908 27.2372 30.3742C27.5806 28.7577 28.4283 27.2729 29.6729 26.1074C30.9176 24.942 32.5034 24.1483 34.2298 23.8268C35.9563 23.5052 37.7457 23.6703 39.372 24.301C40.9982 24.9317 42.3882 25.9998 43.3661 27.3702C44.3441 28.7407 44.866 30.3518 44.866 32C44.866 34.2101 43.9284 36.3298 42.2593 37.8926C40.5903 39.4554 38.3265 40.3333 35.9661 40.3333Z" fill="#E1E1E1"/>
                        </svg>
                        Click here to upload Images
                        </label>
                        <div className="preview-img">
                        <img className="w-100"/>
                        </div>
                    </div>}
                    <Button variant='success' className='w-100' type='submit'>Next <FontAwesomeIcon icon={faAngleRight} /></Button>
                </Col>
            </Row>
        </form>
        <form className='step wrap create-form' onSubmit={handleStoreAdd} style={storeInfo? {display:"block"} : {}}>
            <Row>
                <Col lg={4} md={4}>
                    <FormGroup className='form-group'>
                        <label className='mb-2'>Product Quantity</label>
                        <input type='number' className='form-control' placeholder='Qty' value={inputs.quantity} name='quantity' onChange={(e)=>handleAddToStoreChange(e)} />
                    </FormGroup>
                </Col>
                <Col lg={4} md={4}>
                    <FormGroup className='form-group'>
                        <label className='mb-2'>Product Size</label>
                        <input type='text' className='form-control' placeholder='Size' name='size' onChange={(e)=>handleAddToStoreChange(e)} />
                    </FormGroup>
                </Col>
                <Col lg={4} md={4}>
                    <FormGroup className='form-group'>
                        <label className='mb-2'>Product Color</label>
                        <input type='text' className='form-control' placeholder='Color' name='color' onChange={(e)=>handleAddToStoreChange(e)} />
                    </FormGroup>
                </Col>
                <Col lg={4} md={4}>
                    <FormGroup className='form-group'>
                        <label className='mb-2'>Basic Price</label>
                        <input type='text' className='form-control' placeholder='Basic Price' name='basicPrice' onChange={(e)=>handleAddToStoreChange(e)} required />
                    </FormGroup>
                </Col>
                {vendorUser?.isVerified === true? 
                <Col lg={4} md={4}>
                    <FormGroup className='form-group'>
                        <label className='mb-2'>Select Discount Type</label>
                        <select className='form-control' name='discountType' onChange={(e)=>setdiscountType(e.target.value)} >
                            <option value="">Discount type</option>
                            <option value="discount">Discount</option>
                            <option value="cashback">Cashback</option>
                        </select>
                    </FormGroup>
                </Col> : ""}
                <Col lg={4} md={4}>
                    {
                        vendorUser?.isVerified === true? 
                            discountType === 'discount'? 
                                <FormGroup className='form-group'>
                                    <label className='mb-2'>Discount %</label>
                                    <input type='text' className='form-control' placeholder='Enter (%)' name='discount' value={discountPercent} onChange={(e)=>handlePercentage(e)} /> 
                                </FormGroup>
                                : 
                                discountType === 'cashback'?
                                <FormGroup className='form-group'>
                                    <label className='mb-2'>Cashback</label>
                                    <input type='number' className='form-control' placeholder='Enter amount' name='cashback' onChange={(e)=>handleAddToStoreChange(e)} /> 
                                </FormGroup>
                                : 
                                ""
                            :
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Discount</label>
                                <input type='text' className='form-control' placeholder='Discount (%)' name='discount' value={discountPercent} onChange={(e)=>handlePercentage(e)} />
                            </FormGroup>
                    }
                </Col>
                <Col lg={4} md={4}>
                    <FormGroup className='form-group'>
                        <label className='mb-2'>Sale Price</label>
                        <input type='text' className='form-control' placeholder='Sale Price' name='salePrice' value={discountPrice} onChange={(e)=>handlePercentageFromSalePrice(e)} required />
                    </FormGroup>
                </Col>
                <Col lg={4} md={4}>
                    <FormGroup className='form-group'>
                        <label className='mb-2'>Select Category</label>
                        <div className='select-category'>
                            <input type='text' placeholder='Search Category' className='form-control w-100' value={searchQuery} onChange={(e)=>setsearchQuery(e.target.value)} required />
                            <ul>
                                {allCategory.filter(category => category.status === true).filter((category)=>{
                                    if (searchQuery === "") {
                                        return ""
                                    }else if(category.nameInEnglish.toLowerCase().includes(searchQuery.toLowerCase())){
                                        return category
                                    }
                                }).map((category)=>(
                                    <li>
                                        <label htmlFor={category._id}>{category.nameInEnglish}</label>
                                        <input value={category._id} name='categoryId' onClick={(e)=>{handleAddToStoreChange(e); setsearchQuery(category.nameInEnglish+" ")}} id={category._id} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </FormGroup>
                </Col>
                <Col lg={4} md={4}>
                    
                </Col>
                <Col lg={4} md={4}>
                    <FormGroup className='form-group'>
                        <Button variant='success' className='w-100 mt-4' type='submit'>Next <FontAwesomeIcon icon={faAngleRight} /></Button>
                    </FormGroup>
                </Col>
            </Row>
        </form>
        <div className='step wrap' style={finalizing? {display:"block"} : {}}>
            <div className='step-wrap-inner success-block'>
                <img width={200} src='/imgs/success.gif' />
                <h4>Thanks for adding your product.<br/>Our admin will decide to upload it or not.</h4>
                <Link to='/store' className='btn theme-btn mt-5'>Go back to store</Link>
            </div>
        </div>
    </div>
    </>
  )
}
