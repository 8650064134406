import React from 'react'
import { useState,useEffect } from 'react'
import {useSelector} from 'react-redux'
import { getAllAds, getUddoktaRunningAd, addMedia, updateAdByAdid } from '../../util/apis'
import SingleCampaingItem from './SingleCampaingItem'
import adbanner from '../../img/uddoktabanner.jpg'
import { faEdit,faTimes } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Modal, Row} from 'react-bootstrap'
import Compressor from 'compressorjs'
import axios from 'axios'

export default function Promote() {
  const vendorInfo = useSelector((state)=> state.vendorInfo.currentUser)
  const [findProduct, setfindProduct] = useState(false)
  const [yourProduct, setyourProduct] = useState(true)
  const [list, setlist] = useState([])
  const [runningAds, setrunningAds] = useState([])
  const [error, seterror] = useState("")
  const [show, setshow] = useState(false)
  const [formopen, setformopen] = useState(false)
  const status = 'inbiding'
  const [updateAdInfo, setupdateAdInfo] = useState({})
  const [imgUrl, setimgUrl] = useState(null)
  const [photo, setphoto] = useState(null)

  const fetchCurrentAds = () =>{
      getAllAds(status, vendorInfo?._id, vendorInfo?.accessToken).then((res)=>{
          if (res.status) {
            setlist(res.data)
          } else {
            
          }
      })
  }
  const fetchRunningAds = () =>{
      getUddoktaRunningAd(vendorInfo?._id, vendorInfo?.accessToken).then((res)=>{
          if (res.status) {
            setrunningAds(res.data)
          } else {
            
          }
      })
  }
  useEffect(() => {
      // setloader(true)
      // setTimeout(()=>{
      //     dispatch(updateNotificationSuccess({sms:"",status:null}))
      //     setloader(false)
      // }, 2000)
      fetchCurrentAds()
      fetchRunningAds()
  }, [])
  
  const displayItems = list?.map((item, index) => {
      let lastTimeOfBid = new Date(item?.bidingTimeLimit)
      let adsLimitTime = new Date(item?.adsTimeLimit)
      return (
        <tr key={index}>
            <SingleCampaingItem item={item} lastTimeOfBid={lastTimeOfBid} adsLimitTime={adsLimitTime} />
        </tr>
      );
  });

  const editad = (item) =>{
    setformopen(true)
    setupdateAdInfo(item)
    setimgUrl(item.imgurl)
  }

  const displayCurrentItems = runningAds?.map((item, index) => {
      let adsLimitTime = new Date(item?.adsTimeLimit)
      return (
        <tr key={index}>
            <>
                <div className='single-ad-row w-100'>
                    <div className='singlead-img'>
                        {item?.imgurl? <img src={`${process.env.REACT_APP_BASE_URL}/alluploads/${item?.imgurl}`} width="100%" /> : <img src={adbanner} width="100%" />}
                    </div>
                    <div className='singlead-info w-50'>
                        <h3>{item.title}</h3>
                        <p><span style={{width:'70px'}}>Ad Place</span>: {item.place}</p>
                        <p><span style={{width:'70px'}}>Position</span>: {item.position}</p>
                        <p style={{marginTop:"10px",color:'red'}}>{item?.imgurl? "" : "**Please ad your promotional photo and information**"}</p>
                    </div>
                    <div className='singlead-info w-50'>
                        <h3>৳ {item.minBidLimit} <sub>(Min)</sub></h3>
                        <p><span style={{width:'120px'}}>Ad Expired In</span>: {adsLimitTime?.toLocaleDateString()}</p>
                        <h3>Your Bid: ৳ {item?.maxBidLimit}</h3>
                    </div>
                    <FontAwesomeIcon icon={faEdit} style={{fontSize:"20px", padding:'10px',color:'blue',cursor:'pointer'}} onClick={()=>editad(item)} />
                </div>
            </>
        </tr>
      );
  });

  const handleChange =(e)=>{
    setupdateAdInfo(prev=>{
        return{...prev, [e.target.name]:e.target.value}
    })
  }
  const handleUpdate =(e)=>{
    e.preventDefault()
    if (imgUrl === null) {
        console.log("Upload image");
    } else {
        let data = {...updateAdInfo, imgurl: photo}
        updateAdByAdid(vendorInfo?._id, vendorInfo?.accessToken, data?._id, data).then((res)=>{
            if (res?.status) {
                if (res.status === 200) {
                    setformopen(false)
                    setupdateAdInfo({})
                    setimgUrl(null)
                    setphoto(null)
                    fetchCurrentAds()
                    fetchRunningAds()
                } else {
                    console.log(res);
                }
            } else {
                console.log(res);
            }
        })
    }
  }

  const uploadImg = (e) =>{
    const file = e.target.files[0];
    if (!file) {
        return;
    }
    new Compressor(file, {
        quality: 1,
        width: 410,
        height:186,
    
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          const formData = new FormData();
    
          // The third parameter is required for server
          formData.append('file', result, result.name);
          setphoto(result.name)
    
          // Send the compressed image file to server with XMLHttpRequest.
          try {
            let res = axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/media/upload/${vendorInfo?._id}`, data:formData ,headers:{token: 'Bearer '+ vendorInfo?.accessToken}}).then((res)=>{
                if (res?.data === 'success') {
                    let library = { entityId: vendorInfo?._id, fileName: result.name, fileType: result.type, fileFor:"vendor" }
                    addMedia(vendorInfo?.accessToken, library)
                    .then((res)=>{
                        console.log("success");
                    })
                    .catch((e) => {
                        console.log("error", e);
                    });
                } else {
                    console.log(res);
                }
              })
          } catch (error) {
            
          }
        setimgUrl(URL.createObjectURL(result))
        },
        error(err) {
          console.log(err.message);
        },
      });
  }

  return (
    <>
    <Modal
            show={formopen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header>
                Create Ad Campaign
                <FontAwesomeIcon icon={faTimes} style={{cursor:'pointer'}} onClick={()=>setformopen(false)} />
            </Modal.Header>
          <Modal.Body>
              <form className='custom-form' onSubmit={handleUpdate}>
                  <Row>
                      <div className='col-lg-6 col-md-6'>
                          <div className='form-group'>
                              <label>Ad Title <sup>*</sup></label>
                              <input type='text' className='form-control' value={updateAdInfo?.title} name='title' onChange={(e)=>handleChange(e)} required />
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                          <div className='form-group'>
                              <label>Button Text</label>
                              <input type='text' className='form-control' value={updateAdInfo?.btntext} name='btntext' onChange={(e)=>handleChange(e)} />
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                          <div className='form-group'>
                              <label>Ad Destination url <sup>*</sup></label>
                              <input type='text' className='form-control' name='link' value={updateAdInfo?.link} onChange={(e)=>handleChange(e)} required/>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                          <div className='form-group'>
                              <label>Image alternative text</label>
                              <input type='text' className='form-control' value={updateAdInfo?.alttext} name='alttext' onChange={(e)=>handleChange(e)} />
                          </div>
                      </div>
                      <div className='col-lg-8 col-md-8'>
                          <div className='form-group'>
                            {imgUrl !== null? <img src={imgUrl} onClick={uploadImg} className='w-100' /> : (
                                <div className='imgupload'>
                                    <svg width="72" height="57" viewBox="0 0 72 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M68.0057 10.3334V7.00004H60.8858V10.3334H56.3326L52.7727 0.333374H19.1595L15.5995 10.3334H0.366455V57H71.5657V10.3334H68.0057ZM64.4458 50.3334H7.48638V17H20.733L24.293 7.00004H47.6392L51.1992 17H64.4458V50.3334Z" fill="#E1E1E1"/>
                                    <path d="M35.9661 17C32.7977 17 29.7004 17.8797 27.066 19.528C24.4315 21.1762 22.3782 23.5189 21.1657 26.2597C19.9532 29.0006 19.636 32.0166 20.2541 34.9264C20.8722 37.8361 22.398 40.5088 24.6384 42.6066C26.8788 44.7044 29.7333 46.133 32.8408 46.7118C35.9484 47.2906 39.1694 46.9935 42.0966 45.8582C45.0239 44.7229 47.5258 42.8003 49.2861 40.3336C51.0464 37.8668 51.986 34.9667 51.986 32C51.9812 28.0231 50.2919 24.2104 47.2887 21.3983C44.2854 18.5862 40.2134 17.0044 35.9661 17ZM35.9661 40.3333C34.2059 40.3333 32.4852 39.8446 31.0216 38.9289C29.558 38.0132 28.4173 36.7117 27.7437 35.189C27.0701 33.6663 26.8938 31.9908 27.2372 30.3742C27.5806 28.7577 28.4283 27.2729 29.6729 26.1074C30.9176 24.942 32.5034 24.1483 34.2298 23.8268C35.9563 23.5052 37.7457 23.6703 39.372 24.301C40.9982 24.9317 42.3882 25.9998 43.3661 27.3702C44.3441 28.7407 44.866 30.3518 44.866 32C44.866 34.2101 43.9284 36.3298 42.2593 37.8926C40.5903 39.4554 38.3265 40.3333 35.9661 40.3333Z" fill="#E1E1E1"/>
                                    </svg>
                                    <span>Click here to upload Images</span>
                                </div>
                            )}
                            <input type='file' className='form-control' onChange={uploadImg} />
                            <p className='mt-2'>**Please upload <b>615X270 px</b> Ratio photo for perfect view**</p><br/>
                          </div>
                      </div>
                      <div className='col-lg-12 d-flex justify-content-end mt-3'>
                          <button type='reset' className='btn theme-btn-alt' style={{marginRight:'5px'}}>Reset</button>
                          <button type='submit' className='btn theme-btn'>Save</button>
                      </div>

                      <p className='alert' style={{color:'red'}}>{error.position === 1? "This position already taken" : ""}</p>
                  </Row>
              </form>
          </Modal.Body>
        </Modal>
        <section className='select-products'>
            <ul className='tabs'>
                <li className={yourProduct? "active" : ""} onClick={()=>{setyourProduct(true); setfindProduct(false)}}>Open Ad Campaigns</li>
                <li className={findProduct? "active" : ""} onClick={()=>{setyourProduct(false); setfindProduct(true)}}>Your Running Campaigns</li>
            </ul>
            <div className='tab-wrap'>
                <div className='single-wrap' style={yourProduct? {display:"block"} : {display:"none"}}>
                <table className='table'>
                        <tbody>
                        {displayItems}
                        </tbody>
                </table>
                </div>
                <div className='single-wrap' style={findProduct? {display:"block"} : {display:"none"}}>
                    <table className='table'>
                        <tbody>
                        {displayCurrentItems}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </>
  )
}
