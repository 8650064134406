import { loginStart, loginSuccess, loginFailure, logout, sessionUpdate } from '../redux/authRedux';
import { addBrandFailur, addBrandStart, addBrandSuccess, getBrandFailur, getBrandStart, getBrandSuccess, updateBrandFailure, updateBrandStart, updateBrandSuccess } from '../redux/brandRedux';
import {getCategoryStart,getCategorySuccess,getCategoryFailur,addCategoryStart,addCategorySuccess,addCategoryFailur,updateCategoryStart,updateCategorySuccess,updateCategoryFailure} from '../redux/categoryRedux'
import { addProductFailur, addProductStart, addProductSuccess, getProductFailur, getProductStart, getProductSuccess, updateProductFailure, updateProductStart, updateProductSuccess } from '../redux/productRedux';
import axios from 'axios'
import { siteSettingFailure, siteSettingStart, siteSettingSuccess } from '../redux/settingRedux';


// Login
export const login = async (dispatch, data) =>{
    let res;
    dispatch(loginStart())
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/shop/login`, data:data})
        dispatch(loginSuccess(res.data))
        return res
    } catch (error) {
        dispatch(loginFailure)
        return error
    }
}
// update session
export const updateSession = async (dispatch, id, updateInfo, vartoken) => {
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/shop/${id}`,data:updateInfo, headers:{token: 'Bearer '+ vartoken}} )
      dispatch(sessionUpdate(res.data));
      return res.data;
    } catch (err) {
      return res
    }
  };

  export const siteSetting = async (dispatch) => {
    dispatch(siteSettingStart())
    let res;
    try {
      res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/sitesetting`})
      dispatch(siteSettingSuccess(res.data[0]));
      return res
    } catch (err) {
      dispatch(siteSettingFailure());
      return res
    }
  };

// All Category
export const getAllCategory = async (dispatch) =>{
    let res;
    dispatch(getCategoryStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/category/`})
        dispatch(getCategorySuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getCategoryFailur())
        return res
    }
}

// Add Category
// export const addCategory = async (dispatch, vartoken, data) =>{
//     let res;
//     dispatch(addCategoryStart())
//     try {
//         res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/category/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
//         dispatch(addCategorySuccess(res.data))
//         return res
//     } catch (error) {
//         console.log(error);
//         dispatch(addCategoryFailur())
//         return res
//     }
// }

// Update Category
// export const updateCategory = async (dispatch, vartoken, data, id) => {
//     dispatch(updateCategoryStart());
//     let res;
//     try {
//       res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/category/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
//       const updateCategory = res.data;
//       dispatch(updateCategorySuccess({ id, updateCategory }));
//       return res
//     } catch (err) {
//       dispatch(updateCategoryFailure());
//       return res
//     }
//   };

// Delete Category
export const deleteCategory = async ( vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/category/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// All Brand
export const getAllBrand = async (dispatch) =>{
    let res;
    dispatch(getBrandStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/brand/`})
        dispatch(getBrandSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getBrandFailur())
        return res
    }
}

export const forgotPassword = async (vendorInfo) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/shop/forgotpassword`, data:vendorInfo})
        return res
    } catch (error) {
        console.log(error);
        return error
    }
}

export const sendOtp = async (phone, message) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/shop/forgotpassword/sendOtp/88${phone}`, data:message})
        return res
    } catch (error) {
        console.log(error);
        return error
    }
}

export const changepassword = async (vendormail, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/shop/changepassword/${vendormail}`, data:data})
        return res
    } catch (error) {
        console.log(error);
        return error
    }
}


// All Products
export const getAllProduct = async (dispatch) =>{
    let res;
    dispatch(getProductStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/product?status=true&&addedby=admin`})
        dispatch(getProductSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getProductFailur())
        return res
    }
}

// Add Product
export const addProduct = async (dispatch, vartoken, data) =>{
    let res;
    dispatch(addProductStart())
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/product/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        dispatch(addProductSuccess(res.data))
        return res
    } catch (error) {
        dispatch(addProductFailur())
        return res
    }
}

// Update Product
export const updateProduct = async (dispatch, vartoken, data, id) => {
    dispatch(updateProductStart());
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/product/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      const updateProduct = res.data;
      dispatch(updateProductSuccess({ id, updateProduct }));
      return res
    } catch (err) {
      dispatch(updateProductFailure());
      return res
    }
  };

  // Add to store
  export const addProductToStore = async (vartoken,id, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/store/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}
  // Your Products
  export const getYourProducts = async (id) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/store/yourstore/${id}?status=true`})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}
  // update store product
  export const updateStoreProduct = async (vartoken, data,uid,id) =>{
    let res;
    try {
        res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/store/${uid}/${id}`, data:data, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

export const deleteProdutFromStore = async (vartoken, uid, id) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/store/${uid}/${id}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

export const deleteProduct = async (vartoken, uid, id) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/product/${uid}/${id}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Add Media
export const addMedia = async (vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/media`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Add Media
export const getOrderData = async (vartoken, id) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/order/${id}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

export const updateStatus = async (dispatch, id, updateInfo, vartoken) => {
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/shop/shopstatus/${id}?status=${updateInfo}`, headers:{token: 'Bearer '+ vartoken}} )
      dispatch(sessionUpdate(res.data));
      return res.data;
    } catch (err) {
      return res
    }
  };

// Fetch accounts
export const fetchAccounnts = async (vartoken, from, to, id) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/order/storeaccount/accounts/accountinfo/${id}?from=${from}&to=${to}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Fetch adds
export const getAllAds = async (status, id, vartoken) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/ads/${id}?status=${status}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// ad biding
export const adBid = async (id, vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/biding/${id}`, data:data, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// get bid by ad id
export const getbidbyadidanduddoktaid = async (id, vartoken, adid) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/biding/${id}/${adid}/${id}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

  //get all ad by uddokta runninng
  export const getUddoktaRunningAd = async (id, vartoken) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/ads/${id}?status=active&&hostedBy=${id}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// get updatead by ad id
export const updateAdByAdid = async (id, vartoken, adid, data) =>{
    let res;
    try {
        res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/ads/${id}/${adid}`, data:data, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

export const loadMedia = async (vartoken, id) => {
    let res;
    try {
      res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/media/vendor/${id}`, headers:{token: 'Bearer '+ vartoken}})
      return res
    } catch (err) {
      console.log(err);
      return res
    }
  };

export const uploadMedia = async (vartoken, data, id) => {
    let res;
    try {
      res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/media/upload/shop/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      return res
    } catch (err) {
      console.log(err);
      return res
    }
  };

  export const deleteShopMediaFromStorage = async (vartoken, id, imagename) =>{
    try {
        let res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/media/delete/shop/${id}/${imagename}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return error
    }
}

export const deleteSpecificMedia = async (vartoken, id, imagename) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/media/${id}/${imagename}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return res
    }
}

// fetch pending orders
export const fetchPendingOrders = async (vartoken, id) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/order/shop/pendingorders/myorders/orders/${id}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}