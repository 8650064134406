import React, { useState } from 'react'
import avater from './../../img/Group 24.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faPen, faMinus, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import OrderItem from './OrderItem'
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux'

export default function RunningOrderItems({order}) {
  const [isOpen, setisOpen] = useState(false)
  const contentToPrint = useRef(null);
  const sitesetting = useSelector((state)=>state.siteSetting.siteSetting)
  const riderCost = Number(sitesetting?.riderCost)

  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    // onBeforePrint: () => console.log("before printing..."),
    // onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });
  const getTotal = () => {
    let total = order?.orderedproducts?.map(item => item.basicAmount * item.quantity)
    .reduce((a, b) => a + b, 0);
    return Number(total.toFixed(0));
  }
  const calculatePlatformFee = () =>{
    let finalPlatformFree = 2;
    if (Number(getTotal()) < 300) {
        return finalPlatformFree
    }else if (Number(getTotal()) < 400) {
        finalPlatformFree = 3
        return finalPlatformFree
    } else if (Number(getTotal()) < 500) {
        finalPlatformFree = 4
        return finalPlatformFree
    } else if (Number(getTotal()) < 600) {
        finalPlatformFree = 5
        return finalPlatformFree
    } else if (Number(getTotal()) < 700) {
        finalPlatformFree = 6
        return finalPlatformFree
    } else if (Number(getTotal()) < 800) {
        finalPlatformFree = 7
        return finalPlatformFree
    } else if (Number(getTotal()) < 900) {
        finalPlatformFree = 8
        return finalPlatformFree
    } else {
        finalPlatformFree = 9
        return finalPlatformFree
    }
}
console.log(order);
  return (
    <div className='col-lg-4' ref={contentToPrint}>
        <div className='orderItemCard'>
          <div className='orderItemCardheader'>
            <div className='orderItemCardheaderLeft'>
              <h5>#{order?.orderId}</h5>
              <p>{order?.orderedproducts.length} Items</p>
            </div>
            <div className='d-flex align-items-center'>
              <button className='btn' onClick={() => { handlePrint(null, () => contentToPrint.current);}}>Print</button>
              <FontAwesomeIcon icon={faAngleDown} style={isOpen === true? {transform:'rotate(180deg)'} : ""} onClick={()=>setisOpen(!isOpen)} />
            </div>
          </div>
          <div className='orderItemCardBody' style={isOpen? {display:'block'} : {display:'none'}}>
            <ul>
              {order?.orderedproducts?.map((orderitem,index)=>(
                <OrderItem orderitem={orderitem} key={index} />
              ))}
            </ul>
            <div className='totalbill'>
              <p>Subtotal: <b>Tk. {getTotal()}</b></p>
              <p>Platform Fee: <b>Tk. {calculatePlatformFee()}</b></p>
              <p>Delivery Fee: <b>Tk. {riderCost}</b></p>
              <p>Total: <b>Tk. {getTotal() + calculatePlatformFee() + riderCost}</b></p>
            </div>
            <div className='orderUserAddress'>
                <img src={avater} alt='usersvg' />
                <div>
                  <h6>Customer</h6>
                  <h5>{order?.addresss?.fullname}</h5>
                  <p style={{marginBottom:0}}>{order?.addresss?.phone}</p>
                  <p>{order?.addresss?.address}</p>
                </div>
            </div>
          </div>
          <div className='orderItemCardfooter'>
            <div className='orderItemCardfooterLeft'>
              <button className='btn'><FontAwesomeIcon icon={faPen} />Edit</button>
              <button className='btn btn-alt'>Pickup</button>
              <span>Cash</span>
            </div>
            <div className='orderItemCardfooterRight'>
              <span className='pending circle pulse'></span>
              <p style={{color:'#D7C62D'}}>Preparing</p>
            </div>
          </div>
        </div>
      </div>
  )
}
