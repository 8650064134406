import React, { useEffect, useState } from 'react'
import { updateNotificationSuccess } from '../redux/notificationRedux';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import RunningOrderItems from '../components/runningorder/RunningOrderItems';
import { fetchPendingOrders } from '../util/apis';

export function Dashboard() {
  const dispatch = useDispatch()
  const notification = useSelector((state)=> state.notification.notification)
  const vendorUser = useSelector((state) => state.vendorInfo.currentUser)
  const [pendingOrders, setpendingOrders] = useState([])

  const getPendingOrder = () =>{
    fetchPendingOrders(vendorUser?.accessToken, vendorUser?._id).then((res)=>{
      if (res.status === 200) {
        setpendingOrders(res.data)
      }else{
        console.log("error");
        
      }
    })
  }


  useEffect(() => {
    setTimeout(() => {
      dispatch(updateNotificationSuccess({sms:"",status:null}))
      getPendingOrder()
    }, 2000);
  }, [dispatch])
  

  return (
    <div className='dashboard'>
      <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
          {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
          <p>{notification.sms}</p>
      </div>
      <div className='dashSection'>
        <h4 className='dashSecTitle'>Running Orders</h4>
        <div className='dashOrderList'>
          <div className='row'>
            {pendingOrders.map((order, index) => (
              <RunningOrderItems key={index} order={order} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
