import { faAngleDown, faArrowDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

export default function SingleOrderRow({order,handleUpdateOrder}) {
  const [isopen, setisopen] = useState(false);
  const lastorderstatus = order?.orderStatus[order.orderStatus.length - 1]
  const orderplaced = order?.orderStatus.filter((order)=>{
    if (order.status === 'placed') {
        return order;
    }
  })
  const orderpreparing = order?.orderStatus.filter((order)=>{
    if (order.status === 'preparing') {
        return order;
    }
  })
  let timediffbtwnPlacedaandprep = Math.abs( new Date(orderpreparing[0]?.created_At) - new Date(orderplaced[0]?.created_At))
  let timediffbtwnPlacedaandprepminutes = timediffbtwnPlacedaandprep / (1000 * 60);

  return (
    <li>
        <div className='order-row-top' style={isopen? {"background":"#00000012"} : {"background":"#ffffff"}}>
            <div>
                {lastorderstatus?.status === 'placed'? <p className='custom-badge placed pulseanimatio' onClick={()=>handleUpdateOrder(order?._id, 'preparing')}>Accept Order</p> : lastorderstatus?.status === 'preparing'? <p className='custom-badge preparing'>Preparing</p>: lastorderstatus?.status === 'pickedup'? <p className='custom-badge pickedup'>Pickedup</p> : lastorderstatus?.status === 'delivered'? <p className='custom-badge delivered'>Delivered</p> : <p className='custom-badge canceled'>Canceled</p>} {lastorderstatus?.status === 'preparing'? <p className='custom-badge placed pulseanimatio' onClick={()=>handleUpdateOrder(order?._id, 'delivered')}>Picked Up</p> : ""}
            </div>
            <div>
                <p>#{order?.orderId}</p>
            </div>
            <div>
                {order.transStatus === 'pending'? <p className='custom-badge pending'>Pending</p> : order.transStatus === 'failed'? <p className='custom-badge failed'>Failed</p>: <p className='custom-badge complete'>Complete</p>}
            </div>
            <div>
                {order.totalamount - order.deliveryCharge - 2}
            </div>
            <button onClick={()=>setisopen(!isopen)}><FontAwesomeIcon icon={faAngleDown} /></button>
        </div>
        {isopen? <div className='order-row-bottom d-flex' style={{borderRight:"1px solid #ebebeb",borderLeft:"1px solid #ebebeb"}}>
            <ul className='orderrowdata'>
                {order?.orderedproducts?.map((order)=>(
                    <li>
                        <p>{order.quantity} x {order.storeProductid?.productid?.nameInEnglish}</p>
                        <p>Tk {order.totalAmount}</p>
                    </li>
                ))}
                <li className='mt-2'>
                 <h5>Subtotal:</h5>
                 <h5>Tk {order.totalamount - order.deliveryCharge - 2}</h5>
                </li>
            </ul>
            <ul className='orderstatus'>
                {order?.orderStatus?.map((status)=>(
                    <li>
                        Order {status.status}
                        {status?.status === 'preparing'? <p>Accpted in <b>{timediffbtwnPlacedaandprepminutes.toFixed(2)}</b> min</p> : <p> </p>}
                    </li>
                ))}
            </ul>
        </div> : ""}
    </li>
  )
}
