import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import OpeningDays from './OpeningDays'
import {useDispatch, useSelector} from 'react-redux'
import { updateSession } from '../util/apis'

export default function Opening() {
  const dispatch = useDispatch()
  const vendorUser = useSelector((state) => state.vendorInfo.currentUser)
  const [schedule, setschedule] = useState(vendorUser?.schedule)
  const [isEdit, setisEdit] = useState(true)

  const updateItem = (data) => {
    setisEdit(false)
    setschedule((prevItems) =>
        prevItems.map((item) =>
            item._id === data?._id ? data : item
        )
    );
  };

  const updateShopTimeing = () =>{
    updateSession(dispatch, vendorUser?._id, {schedule:schedule}, vendorUser?.accessToken).then((res)=>{
      if (res === undefined) {
        console.log("Something wrong");
      }else{
        setisEdit(true)
      }
    })
  }
  
  return (
    <div className='openings'>
        <Container fluid>
            <Row>
                <Col lg={7} md={8} sm={12}>
                    <div className='custom-card d-block'>
                        <h5>Set your operation time</h5>
                        <ul>
                            {schedule?.map((day)=>{
                              return (
                                <OpeningDays index={day?._id} info={day} updateItem={updateItem} />
                              )
                            })}
                        </ul>
                        <div className='d-flex w-100 justify-content-end p-2'>
                          <button className='btn theme-btn' type='button' onClick={()=>updateShopTimeing()} disabled={isEdit}>Save</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}
