import { createSlice } from "@reduxjs/toolkit";


const settingSlice = createSlice({
  name: "setting",
  initialState: {
    siteSetting: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    siteSettingStart: (state) => {
      state.isFetching = true;
    },
    siteSettingSuccess: (state, action) => {
      state.isFetching = false;
      state.siteSetting = action.payload;
    },
    siteSettingFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    }
  },
});

export const { siteSettingStart, siteSettingSuccess, siteSettingFailure } = settingSlice.actions;
export default settingSlice.reducer;