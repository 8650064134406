import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

export default function OpeningDays({info, updateItem}) {
    const [timing, settiming] = useState(info)
    const [openingtime, setopeningtime] = useState({
        hr:timing?.openingtime?.split(":")[0],
        min:timing?.openingtime?.split(":",)[1].split(" ")[0],
        hrdiv:timing?.openingtime?.split(" ",)[1]
    })
    const [closingTime, setclosingTime] = useState({
        hr:timing?.closingtime?.split(":")[0],
        min:timing?.closingtime?.split(":",)[1].split(" ")[0],
        hrdiv:timing?.closingtime?.split(" ",)[1]
    })

    const [isEdit, setisEdit] = useState(true)

  return (
    <li className='days'>
        <h6>{info?.day}</h6>
        <div class="button-cover" style={{marginTop:"20px"}}>
            <div class="button r" id="button-1">
            <input type="checkbox" class="checkbox" value={info?.isOpen} checked={info?.isOpen} onChange={(e)=> updateItem({...info, isOpen:e.target.checked})}/>
            <div class="knobs"></div>
            <div class="layer"></div>
            </div>
        </div>
        {isEdit === true?  <FontAwesomeIcon icon={faEdit} onClick={()=>setisEdit(!isEdit)} /> : <FontAwesomeIcon icon={faCheck} onClick={()=>{setisEdit(!isEdit); updateItem({...info, openingtime:`${openingtime.hr}:${openingtime.min} ${openingtime.hrdiv}`, closingtime:`${closingTime.hr}:${closingTime.min} ${closingTime.hrdiv}`})}} />}
        <div className='timing'>
            <div className='form-group'>
            <input type='text' className='form-control' placeholder={openingtime?.hr} onChange={(e)=> setopeningtime({...openingtime, hr:e.target.value})} disabled={isEdit}/>:
            <input type='text' className='form-control' placeholder={openingtime?.min} onChange={(e)=> setopeningtime({...openingtime, min:e.target.value})} disabled={isEdit}/>
            <select onChange={(e)=> setopeningtime({...openingtime, hrdiv:e.target.value})} disabled={isEdit}>
                <option value={openingtime?.hrdiv} selected>{openingtime?.hrdiv}</option>
                <option value="PM">PM</option>
                <option value="AM">AM</option>
            </select>
            </div>
            To
            <div className='form-group'>
            <input type='text' className='form-control' placeholder={closingTime?.hr} onChange={(e)=> setclosingTime({...closingTime, hr:e.target.value})} disabled={isEdit}/>:
            <input type='text' className='form-control' placeholder={closingTime?.min} onChange={(e)=> setclosingTime({...closingTime, min:e.target.value})} disabled={isEdit}/>
            <select onChange={(e)=> setclosingTime({...closingTime, hrdiv:e.target.value})} disabled={isEdit}>
                <option value={closingTime?.hrdiv}>{closingTime?.hrdiv}</option>
                <option value="PM">PM</option>
                <option value="AM">AM</option>
            </select>
            </div>
        </div>
    </li>
  )
}
