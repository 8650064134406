import React, { useEffect } from 'react'
import { UpdateProfile } from '../components/profile/UpdateProfile'

export default function Profile() {

    useEffect(() => {
        
    }, [])

  return (
    <div className='profile'>
      <UpdateProfile />
    </div>
  )
}
